'use client';
import SocialLoginButtons from '@c/SocialLoginButton';
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
} from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import { logEvent } from '@util/analytics';
import {
  createUserDocument,
  getPublicUserDoc,
  getUserByPhone,
  getValidUserDocument,
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import { logError } from '@util/logError';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { FirebaseError } from 'firebase/app';
import { MotionDiv } from 'motion';
import { CountryCode, ParseError, parsePhoneNumber } from 'libphonenumber-js';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { CountryDropdown } from './CountryDropdown';
import UsernameForm from './UsernameForm';
import FormInput from './controls/FormInput';
import FormLabel from './controls/FormLabel';

const AuthForm = ({
  continueFn,
}: {
  continueFn: (uid: string, firstName: string, isSocial: boolean) => void;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const queryClient = useQueryClient();
  const {
    loginEmailPassword,
    loginSocial,
    fetchProvidersForEmail,
    sendPhoneVerification,
    signInWithPhone,
    attribution,
  } = useAuth();
  const [authError, setAuthError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [loginType, setLoginType] = useState<'email' | 'phone' | ''>('phone');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [sendingCode, setSendingCode] = useState(false);
  const [fetchingProviders, setFetchingProviders] = useState(false);
  const [code, setCode] = useState('');
  const [verificationId, setVerificationId] = useState<string>('');
  const [providers, setProviders] = useState<string[]>([]);
  const [countryCode, setCountryCode] = useState<string>('US');

  const login = async () => {
    setIsSubmitting(true);
    const loginResp = await loginEmailPassword({
      email,
      password,
    });
    if (loginResp instanceof FirebaseError) {
      if (loginResp.code === 'auth/wrong-password') {
        const providers = await fetchProvidersForEmail(email);
        if (providers instanceof Error) {
          logError(providers);
          setAuthError(providers.message);
          setIsSubmitting(false);
          return;
        }
        if (
          providers?.length &&
          !providers.includes('your email and password')
        ) {
          const str = providers.join(' or ');
          setAuthError(
            `This account was signed up with ${str}. Please sign in with that provider.`
          );
          setIsSubmitting(false);
          return;
        } else {
          setIsSubmitting(false);
          setAuthError(formatAuthError(loginResp));
        }
      }
      setIsSubmitting(false);
      setAuthError(formatAuthError(loginResp));
    } else {
      logEvent('login', { method: 'email' });
      const redirect = searchParams?.get('redirect');
      router.push(redirect ? redirect : '/');
    }
  };

  const loginWithPhone = async (code: string) => {
    setIsSubmitting(true);
    try {
      const loginResp = await signInWithPhone(verificationId, code);
      if (loginResp instanceof FirebaseError) {
        logError(loginResp);
        setIsSubmitting(false);
        setAuthError(formatAuthError(loginResp));
      } else {
        logEvent('login', { method: 'phone' });
        const redirect = searchParams?.get('redirect');
        router.push(redirect ? redirect : '/');
      }
    } catch (e) {
      setIsSubmitting(false);
      logError(e);
      setAuthError('An error occurred. Please try again or contact support.');
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);
    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(formatAuthError(res));
    } else {
      // check if user doc exists
      const userDoc = await getPublicUserDoc({ uid: res.user.uid });
      if (!userDoc) {
        // if not, create one
        const docData = getValidUserDocument(
          res.user.uid,
          res.user.email ?? '',
          {
            firstName: res.user.displayName
              ? res.user.displayName.split(' ')[0]
              : 'Anonymous',
            lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            opted_out: false,
          },
          attribution
        );
        await createUserDocument(docData, s);
        logEvent('sign_up', { method: s });
        continueFn(
          res.user.uid,
          res.user.displayName?.split(' ')?.[0] ?? '',
          true
        );
        queryClient.invalidateQueries({
          queryKey: ['authUser'],
        });
        return;
      }
      logEvent('login', { method: s });
      const redirect = searchParams?.get('redirect');
      router.push(redirect ? redirect : '/dashboard/my-profile');
    }
  };

  return (
    <div className="mt-[2.4rem] flex w-full items-center justify-center px-[2.4rem] pb-[3.2rem] sm:p-0 lg:mt-[7.7rem]">
      {/* form */}
      <div className="w-full max-w-[40rem]">
        <h1 className="text-[2.4rem] font-semibold">Log in to MX Locker</h1>

        {/* inputs and buttons */}

        <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
          {authError && (
            <div className="w-full text-center">
              <div className="mx-auto w-fit justify-center rounded-md border bg-red-200  px-[1.6rem] py-[0.8rem] font-medium text-red-900">
                {authError}
              </div>
            </div>
          )}

          {loginType === 'phone' && (
            <>
              <FormLabel value="Phone Number">
                <div className="flex gap-[1.6rem]">
                  <CountryDropdown
                    value={countryCode}
                    setValue={setCountryCode}
                  />
                  <FormInput
                    type="tel"
                    value={phone}
                    autoComplete="tel-national"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </FormLabel>
              {showCodeInput && (
                <MotionDiv
                  className="flex flex-col gap-[1.6rem]"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  transition={{ duration: 0.3 }}
                >
                  <FormLabel value="Verification Code">
                    <FormInput
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    />
                  </FormLabel>
                </MotionDiv>
              )}
              {showCodeInput ? (
                <Button
                  text="Log In"
                  type="secondary"
                  onClick={async () => {
                    try {
                      setIsSubmitting(true);
                      await loginWithPhone(code);
                    } catch (e) {
                      logError(e);
                      setAuthError('An error occurred. Please try again.');
                    } finally {
                      setIsSubmitting(false);
                    }
                  }}
                  loading={isSubmitting}
                />
              ) : (
                <Button
                  text="Continue"
                  type="secondary"
                  loading={sendingCode}
                  onClick={async () => {
                    try {
                      setSendingCode(true);
                      const phoneNumber = parsePhoneNumber(
                        phone,
                        countryCode as CountryCode
                      );
                      const userExists = await getUserByPhone(
                        phoneNumber.number
                      );
                      if (!userExists) {
                        setSendingCode(false);
                        setAuthError(
                          'No account found with this phone number. Please sign up first or try logging in with your email.'
                        );
                        return;
                      }
                      setVerificationId(
                        await sendPhoneVerification(phoneNumber.number)
                      );
                      setShowCodeInput(true);
                    } catch (e) {
                      logError(
                        e,
                        undefined,
                        undefined,
                        `send code: ${phone} + ${(e as Error).message}`
                      );
                      if (e instanceof ParseError) {
                        setAuthError('Invalid phone number');
                      } else if (e instanceof FirebaseError) {
                        setAuthError(e.message);
                      } else {
                        setAuthError('An error occurred. Please try again.');
                      }
                    } finally {
                      setSendingCode(false);
                    }
                  }}
                />
              )}

              <Button
                type="underline"
                text="Sign in with Email Instead"
                onClick={() => {
                  setLoginType('email');
                  setAuthError(null);
                }}
              />
            </>
          )}

          {loginType === 'email' && (
            <>
              <FormLabel value="Email">
                <FormInput
                  type="email"
                  value={email}
                  autoComplete="username"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormLabel>
              {providers.includes('your email and password') && (
                <MotionDiv
                  className="flex flex-col gap-[1.6rem]"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  transition={{ duration: 0.3 }}
                >
                  <FormLabel value="Password">
                    <FormInput
                      type="password"
                      value={password}
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormLabel>
                  <Button
                    type="underline"
                    text="Forgot Password?"
                    onClick={() => router.push('/forgot-password')}
                  />
                </MotionDiv>
              )}

              {!providers.length && (
                <Button
                  text="Continue"
                  type="secondary"
                  loading={fetchingProviders}
                  onClick={async () => {
                    try {
                      setFetchingProviders(true);
                      const providers = await fetchProvidersForEmail(
                        email.trim().toLowerCase()
                      );
                      if (providers instanceof Error) {
                        setAuthError(providers.message);
                        return;
                      }
                      if (!providers.length) {
                        setAuthError(
                          'No account found with this email. Please sign up first or try logging in with your phone number.'
                        );
                      }
                      setProviders(providers.map((p) => p.toLowerCase()));
                    } catch (e) {
                      logError(e);
                      setAuthError((e as Error).message);
                    } finally {
                      setFetchingProviders(false);
                    }
                  }}
                />
              )}
              {providers.includes('your email and password') && (
                <Button
                  text="Log In"
                  type="secondary"
                  loading={isSubmitting}
                  onClick={async () => {
                    try {
                      setIsSubmitting(true);
                      await login();
                    } catch (e) {
                      logError(e);
                      setAuthError((e as Error).message);
                    } finally {
                      setIsSubmitting(false);
                    }
                  }}
                />
              )}

              {!!providers.filter((p) => p !== 'your email and password')
                .length && (
                <>
                  <div className="flex h-[2.4rem] justify-between">
                    <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
                    <p>You&apos;ve previously logged in with</p>
                    <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
                  </div>
                  <SocialLoginButtons
                    providers={providers}
                    handleSocialClick={handleSocialClick}
                  />
                </>
              )}

              <Button
                type="underline"
                text="Sign in with Phone Instead"
                onClick={() => {
                  setLoginType('phone');
                  setAuthError(null);
                }}
              />
            </>
          )}

          {/* divider */}
        </div>
        {/* terms of use & privacy policy */}
        {!providers.length && (
          <SocialLoginButtons handleSocialClick={handleSocialClick} />
        )}
        <div className="mt-[4.8rem] flex w-full lg:mt-[6rem]">
          <p>
            New to MX Locker? &nbsp;
            <Link href="/signup" className="font-semibold text-brand-secondary">
              Sign Up
            </Link>
          </p>
        </div>
        <p className="mt-[2.4rem] text-tos lg:mt-[3.2rem]">
          By clicking Log in or Continue with Facebook, Apple or Google, your
          agree to MX Locker&apos;s&nbsp;
          <LinkTargetBlank href="/terms-and-conditions">
            Terms of Service
          </LinkTargetBlank>
          &nbsp;and&nbsp;
          <LinkTargetBlank href="/privacy-policy">
            Privacy Policy
          </LinkTargetBlank>
          .
        </p>
      </div>
    </div>
  );
};

const LoginForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    firstName: '',
    isSocial: false,
  });

  return (
    <TabGroup selectedIndex={tabIndex} onChange={setTabIndex}>
      <TabList className="hidden">
        <Tab></Tab>
        <Tab></Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <AuthForm
            continueFn={(uid, firstName, isSocial) => {
              setUserInfo({ uid, firstName, isSocial });
              setTabIndex(1);
            }}
          />
        </TabPanel>
        <TabPanel>
          <Transition
            appear
            show={tabIndex === 1}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <UsernameForm {...userInfo} />
          </Transition>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};
export default LoginForm;
