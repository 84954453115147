import SocialLoginButtons from '@c/SocialLoginButton';
import PhoneForm from '@c/auth/PhoneForm';
import { CheckmarkIcon } from '@c/icons';
import CheckmarkIconButton from '@c/icons/buttons/CheckmarkButton';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import LinkTargetBlank from '@ui/Link/LinkTargetBlank';
import { logEvent } from '@util/analytics';
import {
  createUserDocument,
  getPublicUserDoc,
  getUserByPhone,
  getValidUserDocument,
} from '@util/firestore/users';
import { formatAuthError } from '@util/index';
import { logError } from '@util/logError';
import { cx } from 'class-variance-authority';
import AuthProvider, { useAuth } from 'context/AuthContext';
import { useToastContext } from 'context/ToastContext';
import { FirebaseError } from 'firebase/app';
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';
import { emailSchema, passwordSchema } from 'models/user';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import UsernameForm from './UsernameForm';
import FormInput from './controls/FormInput';
import FormLabel from './controls/FormLabel';
import { CountryDropdown } from './CountryDropdown';

export const ActiveCheckIcon = ({ active }: { active: boolean }) => {
  return (
    <div
      className={`flex h-[1.3rem] w-[1.3rem] items-center justify-center rounded-full p-[0.3rem] ${
        active ? 'bg-brand-primary' : 'bg-brand-gray'
      }`}
    >
      <CheckmarkIcon variant="white" height={12} width={12} />
    </div>
  );
};

const AuthForm = ({
  continueFn,
}: {
  continueFn: (
    uid: string,
    firstName: string,
    isSocial: boolean,
    phone?: string,
    countryCode?: string
  ) => void;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryCode, setCountryCode] = useState('US');

  const {
    loginSocial,
    createAccountEmailPassword,
    isLoggingIn,
    sendEmailVerification,
    fetchProvidersForEmail,
    attribution,
  } = useAuth();

  const formSchema = z.object({
    email: emailSchema.superRefine(async (email, ctx) => {
      if (email?.includes('@') && email?.includes('.')) {
        const providers = await fetchProvidersForEmail(email);
        if (providers instanceof Error) {
          ctx.addIssue({
            code: 'custom',
            message: providers.message,
          });
        } else if (providers?.length)
          ctx.addIssue({
            code: 'custom',
            message: 'This email is already signed up with MX Locker.',
          });
      }
    }),
    password: passwordSchema,
    consent: z
      .boolean()
      .refine((x) => !!x, 'must agree to ToS and Privacy Policy'),
    opted_out: z.boolean().optional().default(false),
    firstName: z
      .string()
      .min(2, 'First name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
    lastName: z
      .string()
      .min(2, 'Last name must be at least 2 characters')
      .regex(/^[a-zA-Z ]+$/),
    phone: z.string().min(10, 'Phone number must be at least 10 characters'),
  });

  type Form = z.infer<typeof formSchema>;

  const { register, setValue, handleSubmit, formState, watch } = useForm<Form>({
    defaultValues: {
      password: '',
      opted_out: false,
    },
    mode: 'onBlur',
    resolver: zodResolver(formSchema),
  });

  const form = watch();
  const errors = formState.errors;

  const signup: SubmitHandler<Form> = async () => {
    setIsSubmitting(true);
    form.email = form.email.toLowerCase().trim();
    const parsed = parsePhoneNumber(form.phone, countryCode as CountryCode);
    if (!parsed) {
      setIsSubmitting(false);
      setAuthError(
        new FirebaseError('auth/invalid-phone-number', 'Invalid phone number')
      );
      return;
    }
    const userExists = await getUserByPhone(parsed.number);
    if (userExists) {
      setIsSubmitting(false);
      setAuthError(
        new FirebaseError(
          'auth/phone-number-exists',
          'Phone number already in use'
        )
      );
      return;
    }
    const createResp = await createAccountEmailPassword(form);
    if (createResp instanceof FirebaseError) {
      setIsSubmitting(false);
      setAuthError(createResp);
    } else if (
      createResp.user &&
      createResp.user.uid &&
      createResp.user.email
    ) {
      const docData = getValidUserDocument(
        createResp.user.uid,
        createResp.user.email,
        form,
        attribution
      );
      await Promise.allSettled([
        createUserDocument(docData, 'email'),
        sendEmailVerification(),
      ]);
      logEvent('sign_up', { method: 'email' });
      continueFn(
        createResp.user.uid,
        form.firstName,
        false,
        form.phone,
        countryCode
      );
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
    }
  };

  const handleSocialClick = async (s: AuthProvider) => {
    setIsSubmitting(true);
    const res = await loginSocial(s);
    if (res instanceof FirebaseError) {
      setIsSubmitting(false);
      if (!res.message.includes('Pop-up closed by user')) setAuthError(res);
    } else {
      if (res?.user?.uid) {
        try {
          // if this succeeds and returns a user, user already exists
          const userDoc = await getPublicUserDoc({ uid: res.user.uid });
          if (userDoc) {
            const redirect = searchParams?.get('redirect');
            router.push(redirect ?? '/');
            return;
          }
        } catch (e) {
          console.log('No user doc found, creating one now');
        }
        const docData = getValidUserDocument(
          res.user.uid,
          res.user.email ?? '',
          {
            firstName: res.user.displayName
              ? res.user.displayName.split(' ')[0]
              : 'Anonymous',
            lastName: res.user.displayName?.split(' ').pop() ?? 'User',
            opted_out: false,
          },
          attribution
        );
        logEvent('sign_up', { method: s });
        await createUserDocument(docData, s);
        continueFn(
          res.user.uid,
          res.user.displayName?.split(' ')?.[0] ?? '',
          true
        );
      } else {
        setIsSubmitting(false);
        setAuthError(new FirebaseError('auth/unknown', 'unknown error'));
      }
    }
  };

  return (
    <div className="mt-[2.4rem] flex w-full items-center justify-center px-[2.4rem] pb-[3.2rem] lg:mt-[7.7rem]">
      {/* form */}
      <div className="w-full max-w-[36rem]">
        <h1 className="text-[2.4rem] font-semibold">Sign up</h1>
        <h2 className="mt-[0.4rem] text-[1.6rem]">
          Join the largest online community of riders on the Marketplace of
          Motocross.
        </h2>
        {/* inputs and buttons */}
        <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
          {authError && (
            <div className="absolute -top-10 w-full">
              <div className="mx-auto w-fit justify-center rounded-md border bg-red-200 px-[1.6rem] py-[0.8rem] font-medium text-red-900">
                {formatAuthError(authError)}
              </div>
            </div>
          )}
          <form
            className="flex flex-col gap-[2rem]"
            onSubmit={handleSubmit(signup)}
          >
            {/* email */}
            <FormLabel
              required
              value="First Name"
              errorMessage={errors.firstName?.message}
            >
              <FormInput
                placeholder="First name"
                error={!!errors.firstName}
                {...register('firstName')}
                autoComplete="given-name"
              />
            </FormLabel>
            <FormLabel
              required
              value="Last Name"
              errorMessage={errors.lastName?.message}
            >
              <FormInput
                placeholder="Last name"
                error={!!errors.lastName}
                {...register('lastName')}
                autoComplete="family-name"
              />
            </FormLabel>

            <FormLabel
              required
              value="Phone Number"
              errorMessage={errors.phone?.message}
            >
              <div className="flex gap-[1.6rem]">
                <CountryDropdown
                  value={countryCode}
                  setValue={setCountryCode}
                />
                <FormInput
                  placeholder="Enter your phone number"
                  type="tel"
                  {...register('phone')}
                  autoComplete="tel"
                />
              </div>
            </FormLabel>
            <p className="text-[1.2rem]">
              <input
                type="checkbox"
                className="mr-[0.8rem] h-[1.2rem] w-[1.2rem] rounded-sm border border-brand-light-gray text-brand-secondary"
              />
              I agree to receive communication by text message about my
              purchases and orders on MX Locker. You may opt-out by replying
              STOP or reply HELP for more information. Message frequency varies.
              Message and data rates may apply. You may review our Privacy
              Policy to learn how your data is used.
            </p>
            <FormLabel
              required
              value="Email"
              errorMessage={errors?.email?.message}
            >
              <FormInput
                type="email"
                placeholder="Enter your email"
                error={!!errors.email}
                {...register('email')}
                autoComplete="username"
              />
            </FormLabel>

            {/* password */}
            <FormLabel required value="Password">
              <FormInput
                type="password"
                placeholder="Enter your password"
                error={!!errors.password}
                {...register('password')}
                autoComplete="new-password"
              />
              <div className="mt-[0.8rem] flex flex-col gap-[0.8rem] text-[1.3rem]">
                <div className="flex items-center gap-[0.6rem]">
                  <ActiveCheckIcon active={form.password.length >= 8} />
                  <p>8 or more characters</p>
                </div>
                <div className="flex items-center gap-[0.6rem]">
                  <ActiveCheckIcon
                    active={
                      !!form.password.match(/[a-z]/) &&
                      !!form.password.match(/[A-Z]/)
                    }
                  />
                  <p>Upper & lowercase letters</p>
                </div>
                <div className="flex items-center gap-[0.6rem]">
                  <ActiveCheckIcon active={!!form.password.match(/[0-9]/)} />
                  <p>At least one number</p>
                </div>
              </div>
            </FormLabel>

            {/* agree to TOS & Privacy Policy */}
            <div className={cx(['flex items-center gap-[0.8rem]'])}>
              <CheckmarkIconButton
                onClick={() => setValue('opted_out', !form.opted_out)}
                checked={!form.opted_out}
              />
              <p className="text-tos">
                Yes, sign me up to receive exclusive offers, promotions and
                personalized tips for buyer and selling on MX Locker.
              </p>
            </div>
            <div
              className={cx([
                'flex items-center gap-[0.8rem]',
                !!errors.consent ? 'rounded-sm border-2 border-red-500' : '',
              ])}
            >
              <CheckmarkIconButton
                onClick={() => setValue('consent', !form.consent)}
                checked={form.consent}
              />
              <p className="text-tos">
                I agree to&nbsp;
                <LinkTargetBlank href="/terms-and-conditions">
                  Terms of Service
                </LinkTargetBlank>
                &nbsp;and&nbsp;
                <LinkTargetBlank href="/privacy-policy">
                  Privacy Policy
                </LinkTargetBlank>
                .
              </p>
            </div>
            {/* login button */}
            <Button
              text={'Sign up'}
              type="secondary"
              loading={isLoggingIn || isSubmitting}
              buttonType="submit"
            />
          </form>
          {/* divider */}
          <div className="flex h-[2.4rem] justify-between">
            <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
            <p>or continue with</p>
            <div className="mx-[1rem] h-1/2 grow border-b-[1px] border-brand-light-gray" />
          </div>
          <SocialLoginButtons handleSocialClick={handleSocialClick} />
        </div>
        <p className="mt-[2.4rem] text-tos lg:mt-[3.2rem]">
          By clicking continue with Facebook, Apple or Google, your agree to MX
          Locker&apos;s&nbsp;
          <LinkTargetBlank href="/terms-and-conditions">
            Terms of Service
          </LinkTargetBlank>
          &nbsp;and&nbsp;
          <LinkTargetBlank href="/privacy-policy">
            Privacy Policy
          </LinkTargetBlank>
          .
        </p>

        <div className="mt-[4.8rem] flex w-full  lg:mt-[6rem]">
          <p>
            Already have an account?&nbsp;
            <Link href="/login" className="font-semibold text-brand-secondary">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const SignupForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState({
    uid: '',
    firstName: '',
    isSocial: false,
    phone: '',
  });
  const [verificationId, setVerificationId] = useState('');
  const { sendPhoneVerification } = useAuth();
  const { showErrorToast } = useToastContext();

  return (
    <div className="mt-[2.4rem] flex w-full items-center justify-center px-[2.4rem] pb-[3.2rem] sm:p-0 lg:mt-[7.7rem]">
      {/* form */}
      {/* inputs and buttons */}
      <TabGroup selectedIndex={tabIndex} onChange={setTabIndex}>
        <TabList className="hidden">
          <Tab></Tab>
          <Tab></Tab>
          <Tab></Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AuthForm
              continueFn={(uid, firstName, isSocial, phone, countryCode) => {
                setUserInfo({ uid, firstName, isSocial, phone: phone ?? '' });
                // send phone verification
                if (phone && countryCode) {
                  const parsed = parsePhoneNumber(
                    phone,
                    countryCode as CountryCode
                  );
                  if (!parsed) {
                    showErrorToast(
                      'Unable to parse phone number. Please try again.'
                    );
                    setTabIndex(1);
                    return;
                  }
                  getUserByPhone(parsed.number).then((userExists) => {
                    if (userExists) {
                      showErrorToast(
                        'This phone number is already in use. Please try another.'
                      );
                      return;
                    }
                    sendPhoneVerification(parsed.formatInternational())
                      .then((verificationId) => {
                        setTabIndex(1);
                        setVerificationId(verificationId);
                      })
                      .catch((e) => {
                        showErrorToast((e as Error).message);
                        setTabIndex(2);
                        logError(e);
                      });
                  });
                } else {
                  setTabIndex(1);
                }
              }}
            />
          </TabPanel>
          <TabPanel>
            <PhoneForm
              verificationId={verificationId}
              phone={userInfo.phone}
              continueFn={() => {
                setTabIndex(2);
              }}
            />
          </TabPanel>
          <TabPanel>
            <UsernameForm {...userInfo} />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  );
};

export default SignupForm;
