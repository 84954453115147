import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import Button from '@ui/Button';
import { getUserByUsername, updateUsername } from '@util/firestore/users';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import FormInput from './controls/FormInput';
import FormLabel from './controls/FormLabel';
import { usernameSchema } from 'models/user';

const UsernameForm = ({
  uid,
  // firstName,
  isSocial,
}: {
  uid: string;
  firstName: string;
  isSocial: boolean;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, formState, setError } = useForm<{
    username: string;
  }>({
    defaultValues: {
      username: '',
    },
    mode: 'onBlur',
    resolver: zodResolver(z.object({ username: usernameSchema })),
  });
  const errors = formState.errors;

  const submit: SubmitHandler<{ username: string }> = async (data) => {
    setIsSubmitting(true);
    const usernameExists = await getUserByUsername(data.username);
    if (usernameExists) {
      setError('username', {
        type: 'manual',
        message: 'Username is already taken',
      });
      setIsSubmitting(false);
      return;
    }
    await updateUsername(uid, data.username);
    await queryClient.invalidateQueries({
      queryKey: ['authUser'],
    });
    if (isSocial) router.push('/dashboard/edit-profile');
    else router.push('/verify-email');
    setIsSubmitting(false);
  };

  return (
    <div className="mx-auto w-full max-w-[36rem]">
      <h1 className="text-[2.4rem] font-semibold">Create a Username</h1>
      <h2 className="mt-[0.4rem] text-tos">
        This will be displayed publicly to other users.
      </h2>
      <div className="relative mt-[2.4rem] flex flex-col gap-[2rem] lg:mt-[3.2rem]">
        <form
          className="flex flex-col gap-[2rem]"
          onSubmit={handleSubmit(submit)}
        >
          <FormLabel
            required
            value="Username"
            errorMessage={errors.username?.message}
          >
            <FormInput
              placeholder="Username"
              error={!!errors.username}
              autoComplete="off"
              {...register('username')}
            />
          </FormLabel>
          <div className="mt-5 w-full">
            <Button
              text="Submit"
              type="secondary"
              loading={isSubmitting}
              buttonType="submit"
              width="fluid"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UsernameForm;
