import Button from '@ui/Button';
import AuthProvider from 'context/AuthContext';
import { FacebookBrandIcon, GoogleBrandIcon, AppleBrandIcon } from './icons';

const SocialLoginButtons = ({
  handleSocialClick,
  providers,
}: {
  handleSocialClick: (s: AuthProvider) => Promise<void>;
  providers?: string[];
}) => (
  <div className="flex gap-[0.4rem]">
    {(!providers || providers.includes('facebook')) && (
      <Button
        text={''}
        leadingIcon={<FacebookBrandIcon />}
        type="sso"
        onClick={() => handleSocialClick('facebook')}
        width="fluid"
        ariaText="login with facebook"
      />
    )}
    {(!providers || providers.includes('google')) && (
      <Button
        text={''}
        leadingIcon={<GoogleBrandIcon />}
        type="sso"
        onClick={() => handleSocialClick('google')}
        width="fluid"
        ariaText="login with google"
      />
    )}
    {(!providers || providers.includes('apple')) && (
      <Button
        text={''}
        leadingIcon={<AppleBrandIcon />}
        type="sso"
        onClick={() => handleSocialClick('apple')}
        width="fluid"
        ariaText="login with apple"
      />
    )}
  </div>
);

export default SocialLoginButtons;
