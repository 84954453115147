const LinkTargetBlank = ({ href, children }: any) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="font-medium text-brand-gray underline"
      href={href}
    >
      {children}
    </a>
  );
};

export default LinkTargetBlank;
